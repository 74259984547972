<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="justify-content-center auth-container">
        <div class="card">
          <div class="card-body p-0">
            <div>
              <div v-if="config" class="mx-auto mb-5 login-header py-3">
                <a routerLink="/">
                  <img
                      src="@assets/images/logo.png"
                      style="display:block;margin:auto;height:60px;max-width:100%;"
                  />
                </a>
              </div>
              <div class="pr-5 pl-5 pb-5">
                <h6 class="h5 mb-0 mt-4 mb-4">Change password!</h6>
                <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
                  <form
                    class="login-form"
                    @submit.prevent="handleSubmit(handleSend)"
                  >
                    <TextInput
                      v-model="credentials.password"
                      name="password"
                      rules="required|confirmed:confirm_password"
                      label="Password"
                      type="password"
                    />

                    <TextInput
                      v-model="credentials.password_confirmation"
                      vid="confirm_password"
                      name="confirm_password"
                      rules="required"
                      label="Confirm Password"
                      type="password"
                    />
                    <b-form-group id="button-group" class="mt-4 mb-1">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="btn-block btn-dark-blue"
                        :disabled="loadingSend || (invalid && touched)"
                      >
                        <b-spinner v-if="loadingSend" small /> Send
                      </b-button>
                    </b-form-group>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      credentials: {
        password: '',
        password_confirmation: '',
        token: '',
      },
      loadingSend: false,
    }
  },

  computed: {
    config() {
      return this.$store.getters['app/config']
    },
  },

  mounted() {
    this.credentials.token = this.$route.query.token
  },

  methods: {
    handleSend() {
      this.loadingSend = true
      this.$store
        .dispatch('auth/changePassword', this.credentials)
        .then(() => {
          this.loadingSend = false
          this.$router.push({ name: 'login' })
        })
        .catch(() => {
          this.loadingSend = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-header{
  background: #284373;
}
.auth-container {
  width: 460px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }

  .input-group-text {
    padding: 5px 10px;
  }
}
</style>
